import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state,action) => {
      state.cart = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
    setCart,
} = cartSlice.actions;

/**
 * Reducers
 */
export const cartReducer = cartSlice.reducer;
