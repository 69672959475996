import axios from "axios";
import qs from "qs";
import { KEYS } from "../utils/constant";
import { getToken, LocalStorage } from "../utils/localStorage";
import toast from "react-hot-toast";

const baseUrl =  process.env.REACT_APP_API_URL;
console.log('baseUrl',baseUrl);
const GET = "GET";
const DELETE = "DELETE";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";

let cache = [];

const cancel = [];

const ACTION_HANDLERS = {
    [GET]: (url, data, headers) => {
        let queryUrl = url;

        if (data !== undefined) {
            const query = qs.stringify(data);
            queryUrl = `${queryUrl}?${query}`;
        }

        return axios.get(baseUrl + queryUrl, {
            cancelToken: new axios.CancelToken((cToken) => {
                cancel.push({ url, cToken });
            }),
            headers,
        });
    },

    [DELETE]: (url, data, headers) =>
        axios.delete(baseUrl + url, {
            headers,
            data,
        }),

    [POST]: (url, data, headers) =>
        axios.post(baseUrl + url, data, {
            headers,
        }),

    [PATCH]: (url, data, headers) =>
        axios.patch(baseUrl + url, data, {
            headers,
        }),

    [PUT]: (url, data, headers) =>
        axios.put(baseUrl + url, data, {
            headers,
        }),
};

function setHeaders({
    contentType,
    authToken = true,
    isServer = false,
    userAgent,
}) {
    const headers = {};

    if (isServer) {
        delete axios.defaults.headers.common.Authorization;
    }

    if (authToken && !isServer) {
        const token = getToken();
        console.log('token',token);
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    }

    // headers[KEYS.deviceId] = LocalStorage.get(KEYS.deviceId);
    // headers[KEYS.domain] = LocalStorage.get(KEYS.domain);
    // headers[KEYS.sub] = LocalStorage.get(KEYS.sub);
    // headers[KEYS.authType] = LocalStorage.get(KEYS.authType);

    // headers[KEYS.deviceId] = "device01";
    headers[KEYS.domain] = "1+IimNGDRpY7+qZwRVI+P14zzdsb608fxt2O1iclHG3Q6QwerJoEmhcX4A07HH02";
    headers[KEYS.sub] = "XN0HOcVKxTT0pusa1ZcfGQ==";
    headers[KEYS.authType] = "iFgeKbvi1QPj6AQ7nzw04A==";
    


    if (userAgent) {
        headers["User-Agent"] = userAgent;
    }

    if (contentType) {
        headers["Content-Type"] = contentType;
        headers.Accept = "application/json";
    }

    return headers;
}

function handleError(error) {
    cache = [];
    const { response = {} } = error || {};
    const UnauthorizedCode = 401;
    const ForbiddenCode = 403;
    const InternalServerErrorCode = 500;
    // toast.error(response?.data?.error);

    // console.error("Error during fetchUrl:", response.status);


    if (response.status === InternalServerErrorCode) {
        toast.error("Internal Server Error");
    }
    if (response.status === ForbiddenCode) {
        // window.location.href = "/auth/sign-in";
        // localStorage.clear();
        // return false;
    }

    if (response.status === UnauthorizedCode) {
        toast.error(response?.data?.error);
        return false;
    }

    if (
        response.status === UnauthorizedCode &&
        typeof window !== "undefined" &&
        !response?.data?.DATA?.hasOwnProperty("confirmed")
    ) {
        LocalStorage.clean();
        return false;
    }
}

const cacheHandler = (url, { shouldRefetch, handleCache = true }) => {
    if (!shouldRefetch && handleCache) {
        if (cache.includes(url)) {
            const controller = cancel.filter((index) => index.url === url);
        } else {
            cache.push(url);
        }
    }
};

const fetchUrl = ({ type, url, data = {}, config = {} }) => {
    const token = getToken();
    if (token) {
        config = {
            ...config,
            authToken: true,
        };
    }
    
    const headers = setHeaders(config);
    cacheHandler(url, config);
    const handler = ACTION_HANDLERS[type.toUpperCase()];
    console.log('data',data);
    return handler(url, data, headers)
        .then((response) => Promise.resolve(response.data))
        .catch(handleError);
};

export default fetchUrl;