import {
  setProduct,
  setProducts,
  setCategorys
} from '../redux/product/productSlice';

import fetchUrl from "../api";
import { USER_APIS, ECOMMERCE_APIS } from "../utils/constant";
import { toast } from "react-hot-toast";


export function getCategoryListAction(data) {
  return async (dispatch) => {
    try {
      const type = 'get';
      const url = `${ECOMMERCE_APIS.GET_ACTIVE_CATEGORY}`;
      const response = await fetchUrl({ type, url });
      
      if (response && response.data && response.status == 200) {
        dispatch(setCategorys(response.data));
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}


export function getProductListAction(data) {
  return async (dispatch) => {
    try {

      const type = 'post';
      const url = `${ECOMMERCE_APIS.GET_PRODUCTS}`;

      const response = await fetchUrl({ type, url,data });
      
      if (response && response.data && response.status == 200) {
        dispatch(setProducts(response.data));
      } else {
        dispatch(setProducts({
          count:0,
          rows:[]
      }));

      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}

export function getProductDetailAction(id) {
  return async (dispatch) => {
    try {

      const type = 'get';
      const url = `${ECOMMERCE_APIS.GET_PRODUCT_DETAIL.replace('<product_id>',id)}`;

      const response = await fetchUrl({ type, url });
      
      if (response && response.data && response.status == 200) {
        dispatch(setProduct(response.data));
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}