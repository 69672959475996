import React, { useState, memo, Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Row,
  Col,
  Image,
  Nav,
  Tab,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { BrowserRouter, Link } from 'react-router-dom'

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Autoplay } from "swiper";
// import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";
import { useNavigate } from "react-router-dom";

import "swiper/css";

import { useDispatch, useSelector } from 'react-redux';

import { selectCart, selectProducts } from '../redux/cart/cartSelector';

import { getCarts, addToCartAction, deleteCartAction } from '../actions/cartActions'

import { selectAddress } from '../redux/address/addressSelector';
import { getAddresses, addAddress } from '../actions/addressActions'


import { selectOrderAddress } from '../redux/order/orderSelector';
import { setOrderAddress } from '../redux/order/orderSlice'

import { addOrder } from '../actions/orderAction'




import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";



import ProductCard from "../components/ProductCard";
import ProductCardListView from "../components/ProductCardListView";
import OfferCard from "../components/OfferCard";

import FilterOptions from "../components/FilterOptions";
import FilterRating from "../components/FilterRating";
import RangeSlider from "../components/RangeSlider";
import Card from "../components/bootstrap/card";

import ShareOffcanvas from "../components/partials/components/shareoffcanvas";
import * as yup from "yup";


import img1 from "../assets/modules/e-commerce/images/product-detail/10.png";
import img2 from "../assets/modules/e-commerce/images/product-detail/11.png";
import img3 from "../assets/modules/e-commerce/images/product-detail/12.png";
import img4 from "../assets/modules/e-commerce/images/product-detail/13.png";
import img5 from "../assets/modules/e-commerce/images/product-detail/14.png";
import img6 from "../assets/modules/e-commerce/images/user/04.png";
import img7 from "../assets/modules/e-commerce/images/user/01.png";
import img8 from "../assets/modules/e-commerce/images/01.png";
import img9 from "../assets/modules/e-commerce/images/02.png";
import img10 from "../assets/modules/e-commerce/images/03.png";
import img11 from "../assets/modules/e-commerce/images/04.png";
import img12 from "../assets/modules/e-commerce/images/05.png";
import img13 from "../assets/modules/e-commerce/images/06.png";
import img14 from "../assets/modules/e-commerce/images/product-detail/2.png";
import img15 from "../assets/modules/e-commerce/images/product-detail/3.png";
import img16 from "../assets/modules/e-commerce/images/product-detail/4.png";
import img17 from "../assets/modules/e-commerce/images/product-detail/5.png";
import img18 from "../assets/modules/e-commerce/images/product-detail/1.png";
import Counter from "../components/counter";


// Sweetalert2
import Swal from "sweetalert2";
// import "shepherd.js/dist/css/shepherd.css";
// import "flatpickr/dist/flatpickr.css";
// import "choices.js/public/assets/styles/choices.min.css";
import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";

import "../assets/modules/e-commerce/scss/e-commerce.scss"
import Header from "../components/e-commerce/Header";

const translationPercentage = 10;
const scaleValues = { x: 0.9, y: 1 };


const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  contact_no: yup.string().required('Contact No. is required'),
  email: yup.string().nullable().required("Email is required."),
  address1: yup.string().required('Address 1 is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipcode: yup.string().required('Zipcode is required'),
});



const OrderProcess = () => {
  const [open, setOpen] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const dispatch = useDispatch();
  const cartItems = useSelector(selectCart);
  const addresses = useSelector(selectAddress);
  const orderAddress = useSelector(selectOrderAddress);


  let navigate = useNavigate();


  useEffect(() => {
    dispatch(getCarts())
    dispatch(getAddresses())

  }, [])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // name: "",
      // description: "",
      // category: "",
      // inventory_quantity: "",
      // sku: "",
      // barcode: "",
      // weight: "",
      // status: "",


    },
  });


  const placeOrder = async () => {
    let bodyData = {
      "billing_address": {
        "first_name": orderAddress?.first_name,
        "last_name": orderAddress?.last_name,
        "contact_no": orderAddress?.contact_no,
        "email": orderAddress?.email,
        "address1": orderAddress?.address1,
        "address2": null,
        "city": orderAddress?.city,
        "state": orderAddress?.state,
        "zipcode": orderAddress?.zipcode,
        "is_default": orderAddress?.is_default,

      },
      "shipping_address": {
        "first_name": orderAddress?.first_name,
        "last_name": orderAddress?.last_name,
        "contact_no": orderAddress?.contact_no,
        "email": orderAddress?.email,
        "address1": orderAddress?.address1,
        "address2": null,
        "city": orderAddress?.city,
        "state": orderAddress?.state,
        "zipcode": orderAddress?.zipcode,
        "is_default": orderAddress?.is_default,

      },
      "card_details": {
        "card_number": "4111111111111111",
        "cvv": "123",
        "expiry_month": "4",
        "expiry_year": "2030",
        "card_holder_name": "Deepak Suthar"
      }
    }
    let isPlaced = await dispatch(addOrder(bodyData))

    if (isPlaced) {
      navigate("/invoice");
    }
  }


  const fetchImage = (images) => {

    if (images && images.length > 0) {
      let findMainImage = images.find((item) => item.is_main === true)

      if (findMainImage) {
        return findMainImage?.image
      }
      else {
        return images[0]?.image
      }
    }

  }

  const calculateSubTotal = () => {
    let subtotal = 0;
    if (cartItems && cartItems?.items?.length > 0) {

      cartItems?.items?.map(item => {

        if (item?.variant) {
          subtotal += (item.variant.price * item.quantity);
        }
        else if (!item?.variant && item?.product) {
          subtotal += (item.product.price * item.quantity);
        }
      });

      return subtotal.toFixed(2);
    }
    else {
      return 0.00
    }

  }

  const onAddressSubmit = async (data) => {
    console.log('data', data);

    let isAdded = await dispatch(addAddress(data))

    if (isAdded) {
      reset()
    }
    console.log('isAdded', isAdded);

  }


  const orderTotal = () => {
    let subtotal = 0;
    if (cartItems && cartItems?.items?.length > 0) {

      cartItems?.items?.map(item => {

        if (item?.variant) {
          subtotal += (item.variant.price * item.quantity);
        }
        else if (!item?.variant && item?.product) {
          subtotal += (item.product.price * item.quantity);
        }
      });

      return subtotal.toFixed(2);
    }
    else {
      return 0.00
    }

  }


  const handleQuantity = (item, quantity) => {
    if (quantity >= 1) {
      dispatch(addToCartAction({
        "product": item?.product?.id,
        "variant": item?.variant?.id ? item?.variant?.id : null,
        "quantity": quantity
      }, false))
      console.log("quantity", quantity);
    }


  }

  const [productList, setProductList] = useState([
    {
      image: img1,
      name: "Biker’s Jacket",
      color: "Red & Black",
      size: " L",
      price: "$80.00",
    },
    {
      image: img2,
      name: "Pink Sweater",
      color: "Pink",
      size: "M",
      price: "$70.00",
    },
    {
      image: img3,
      name: "Beats Headphones",
      color: "Green",
      size: "M",
    },
    {
      image: img4,
      name: "Shoes",
      color: "Yellow",
      size: "8",
    },
  ]);
  function Sweetalert() {
    Swal.fire({
      icon: "success",
      title: "Added!",
      text: "Your item has been Added to the cart.",
    });
  }
  const cart = () => {
    document.getElementById("cart").classList.add("show");
    document.getElementById("checkout").classList.remove("show");
    document.getElementById("payment").classList.remove("show");
    document.getElementById("iq-tracker-position-1").classList.add("active");
    document.getElementById("iq-tracker-position-2").classList.remove("active");
    // document.getElementById("iq-tracker-position-2").classList.add("done");
    // document.getElementById("iq-tracker-position-3").classList.add("active");
  };

  const checkout = () => {

    navigate("/checkout");

    // document.getElementById("cart").classList.remove("show");
    // document.getElementById("checkout").classList.add("show");
    // document.getElementById("iq-tracker-position-1").classList.remove("active");
    // document.getElementById("iq-tracker-position-1").classList.add("done");
    // document.getElementById("iq-tracker-position-2").classList.add("active");
  };
  const payment = () => {
    navigate("/payment");

    // document.getElementById("checkout").classList.remove("show");
    // document.getElementById("payment").classList.add("show");
    // document.getElementById("iq-tracker-position-2").classList.remove("active");
    // document.getElementById("iq-tracker-position-2").classList.add("done");
    // document.getElementById("iq-tracker-position-3").classList.add("active");
  };

  const handleRemove = (index, id) => {
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCartAction(id))
      }
    });
  };

  return (
    <Fragment>
      <Header/>
      <Row>
        <ul
          className="text-center iq-product-tracker mb-0 py-4"
          id="progressbar"
        >
          <li
            className="active iq-tracker-position-0"
            id="iq-tracker-position-1"
          >
            Cart
          </li>
          <li className="iq-tracker-position-0" id="iq-tracker-position-2">
            Checkout
          </li>
          <li className="iq-tracker-position-0" id="iq-tracker-position-3">
            Payment
          </li>
        </ul>
        <div id="cart" className="iq-product-tracker-card show b-0">
          <Row>
            <Col lg="8">
              <Card>
                <div className="card-header">
                  <h4>My Cart</h4>
                </div>
                <Card.Body className="p-0">
                  <Table responsive className="mb-0">
                    <tbody>
                      {cartItems && cartItems?.items?.map((item, index) => {
                        return (
                          <tr data-item="list" key={index}>
                            <td>
                              <div className="d-flex align-items-center gap-4">
                                <Image
                                  src={fetchImage(item?.product?.products_images)}
                                  alt="image"
                                  className="img-fluid object-contain avatar-100 iq-product-bg p-3"
                                  loading="lazy"
                                />
                                <div>
                                  <h5 className="mb-3">{item.product.name}</h5>

                                    {
                                      item?.variant ?
                                      <span className="mb-3">{item.variant.name}</span> : null
                                    }

                                </div>
                              </div>
                            </td>
                            <td>
                              <Counter handleQuantity={handleQuantity} item={item} quantity={item?.quantity} />
                            </td>
                            <td>
                              <div className="d-flex gap-3">
                                <p className="text-decoration-line-through mb-0">
                                  {item.price}
                                </p>
                                <Link to="#" className="text-decoration-none">
                                  ${
                                    item?.variant?.price ? item?.variant?.price : item?.product?.price
                                  }
                                </Link>
                              </div>
                            </td>
                            <td>
                              <button
                                className="btn btn-icon btn-danger btn-sm delete-btn"
                                onClick={() => handleRemove(index, item.id)}
                              >
                                <span className="btn-inner">
                                  <svg
                                    className="icon-32"
                                    width="32"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.4"
                                      d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <div className="card-header">
                  <h4 className="mb-0">Order Summary</h4>
                </div>
                <Card.Body>
                  <div className="border-bottom mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6>Subtotal</h6>
                      <h6 className="text-primary">${calculateSubTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6>Shipping</h6>
                      <h6 className="text-success">FREE</h6>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Order Total</h6>
                      <h5 className="text-primary mb-0">${orderTotal()}</h5>
                    </div>
                    <div className="d-flex">
                      <Button
                        id="place-order"
                        to="#"
                        onClick={checkout}
                        variant="primary d-block mt-3 next w-100"
                      >
                        Checkout
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="checkout" className="iq-product-tracker-card b-0">
          <Row>
            <Col lg="8">

              {
                addresses && addresses.length > 0 ?
                  <Card>
                    <Card.Body>
                      <h4 className="mb-0">Most Recently Used</h4>
                      <Row>
                        {
                          addresses.map((address, index) => (
                            <Col lg="4">
                              <div className="mt-4">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="address"
                                    id={'address_' + index}

                                    onClick={() => dispatch(setOrderAddress(address))}
                                    checked={orderAddress?.id === address?.id}
                                  // defaultChecked
                                  />
                                  <label
                                    className="form-check-label mb-0"
                                    htmlFor={'address_' + index}
                                  >
                                    <span className="h6 mb-0">Billing Address</span>
                                  </label>
                                </div>
                                <p className="mb-0">{address?.first_name} {address?.last_name}</p>
                                <p className="mb-0">
                                  {address?.address1}, {address?.city}, {address?.state}. {address?.zipcode}{" "}
                                </p>
                                <div className="mt-2 d-flex gap-1">
                                  <Link to="#">Edit</Link>
                                  <span>|</span>
                                  <Link to="#">Remove </Link>
                                </div>
                              </div>
                            </Col>
                          ))
                        }
                      </Row>
                    </Card.Body>
                  </Card> : null
              }

              <Card>
                <Card.Header>
                  <h4 className="mb-0">Add New Address</h4>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="fName">First Name</Form.Label>
                          <Form.Control type="text" id="fName" name="first_name"

                            {...register('first_name', {
                              required: true,
                            })} />

                          {errors.first_name && <span className="error-msg" style={{ color: 'red' }}>{errors.first_name.message}</span>}

                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="LName">Last Name</Form.Label>
                          <Form.Control type="text" id="LName"   {...register('last_name', {
                            required: true,
                          })} />

                          {errors.last_name && <span className="error-msg" style={{ color: 'red' }}>{errors.last_name.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Cno">Contact Number</Form.Label>
                          <Form.Control type="email" id="Cno"  {...register('contact_no', {
                            required: true,
                          })} />

                          {errors.contact_no && <span className="error-msg" style={{ color: 'red' }}>{errors.contact_no.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="email">Email ID</Form.Label>
                          <Form.Control type="text" id="email"  {...register('email', {
                            required: true,
                          })} />

                          {errors.email && <span className="error-msg" style={{ color: 'red' }}>{errors.email.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="HName">
                            Address 1
                          </Form.Label>
                          <Form.Control type="text" id="HName" {...register('address1', {
                            required: true,
                          })} />

                          {errors.address1 && <span className="error-msg" style={{ color: 'red' }}>{errors.address1.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Lmark">Address 2</Form.Label>
                          <Form.Control type="text" id="Lmark" {...register('address2', {
                            required: true,
                          })} />

                          {errors.address2 && <span className="error-msg" style={{ color: 'red' }}>{errors.address2.message}</span>}
                        </div>
                      </Col>

                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Town">Town/ City</Form.Label>
                          <Form.Control type="text" id="Town"  {...register('city', {
                            required: true,
                          })} />

                          {errors.city && <span className="error-msg" style={{ color: 'red' }}>{errors.city.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="State">
                            State/ Country
                          </Form.Label>
                          <Form.Control type="text" id="State"  {...register('state', {
                            required: true,
                          })} />

                          {errors.state && <span className="error-msg" style={{ color: 'red' }}>{errors.state.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Zip">
                            Postcode/ ZIP Code
                          </Form.Label>
                          <Form.Control type="text" id="Zip" {...register('zipcode', {
                            required: true,
                          })} />

                          {errors.zipcode && <span className="error-msg" style={{ color: 'red' }}>{errors.zipcode.message}</span>}
                        </div>
                      </Col>

                      <Col lg="12" className="d-flex my-4">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="Daddress"
                            className="form-check-input"
                            name="is_default"
                            {...register('is_default', {

                            })} />

                          {errors.is_default && <span className="error-msg" style={{ color: 'red' }}>{errors.is_default.message}</span>}
                          <label
                            className="form-check-label"
                            htmlFor="Daddress"
                          >
                            Make this my default address.
                          </label>
                        </div>
                      </Col>
                      <div className="d-flex">
                        <Button type="button" onClick={handleSubmit(onAddressSubmit)} className="btn btn-primary">
                          Submits
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Payment Summary</h4>
                </Card.Header>
                <Card.Body>
                  <div className="border-bottom mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Subtotal</h6>
                      <h6 className="mb-0 text-primary">${calculateSubTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Shipping</h6>
                      <h6 className="mb-0 text-primary">FREE</h6>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Order Total</h6>
                      <h6 className="mb-0 text-primary">${orderTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <Button
                        id="backbutton"
                        href="#"
                        variant=""
                        onClick={cart}
                        className="btn-primary-subtle d-block back justify-content-between"
                      >
                        Back
                      </Button>
                      <Button
                        id="deliver-address"
                        href="#"
                        onClick={payment}
                        className="btn btn-primary d-block"
                      >
                        Place Order
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="payment" className="iq-product-tracker-card b-0">
          <Row>
            <Col lg="8">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Payment Options</h4>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex mt-3">
                      <Image className="img-fluid" src={img5} alt="01" />
                      <h6 className="mb-0 ms-3">
                        Barclays Bank Debit Card ending in 7852
                      </h6>
                    </div>
                    <h6 className="mb-0 mt-3">Elon Musk</h6>
                    <h6 className="mb-0 mt-3">06 / 2030</h6>
                  </div>
                  <Form className="mt-4">
                    <div className="d-flex align-items-center">
                      <div className="mb-4">
                        <Form.Label>
                          <span className="h6">Enter CVV</span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="xxx" required />
                      </div>
                      <Button
                        type="submit"
                        className="btn btn-primary mt-2 ms-3"
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                  <hr />
                  <div className="card-lists">
                    <div className="form-group mt-4">
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="credit"
                          name="customRadio"
                          className="form-check-input"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="credit"
                        >
                          <span className="h6">Credit / Debit / ATM Card</span>
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="netbaking"
                          name="customRadio"
                          className="form-check-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="netbaking"
                        >
                          <span className="h6"> Net Banking</span>
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="emi"
                          name="customRadio"
                          className="form-check-input"
                        />
                        <label className="custom-control-label" htmlFor="emi">
                          <span className="h6">EMI (Easy Installment)</span>
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="cod"
                          name="customRadio"
                          className="form-check-input"
                        />
                        <label className="custom-control-label" htmlFor="cod">
                          <span className="h6">Cash On Delivery</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" className="btn btn-primary mt-2">
                      Add Gift Card
                    </Button>
                    <Button
                      className="btn btn-primary mt-2"
                      href="#"
                      role="button"
                    >
                      cancel
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Billing Details</h4>
                </Card.Header>
                <Card.Body>
                  <hr />
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Subtotal</h6>
                      <h6 className="mb-0 text-primary">${calculateSubTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Shipping</h6>
                      <h6 className="mb-0 text-primary">FREE</h6>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <h6 className="mb-0">Amount Payable</h6>
                    <h6 className="mb-0 text-primary">${orderTotal()}</h6>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <Button
                    type="button"
                    className="btn btn-primary mt-2 ms-3"

                    onClick={() => placeOrder()}
                  >
                    Finish Payment
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      </Row>
    </Fragment>
  );
};

export default OrderProcess;