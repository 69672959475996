import React, { Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Row,
    Col
} from "react-bootstrap";

import "swiper/css";
import { useSelector } from 'react-redux';
import { selectOrder } from '../redux/order/orderSelector';

import Card from "../components/bootstrap/card";

import { generateOrderId } from "../utils/helper";

import successIcon from "../assets/images/icons/success-icon.svg";
import { useNavigate } from 'react-router-dom';


import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";
import "../assets/modules/e-commerce/scss/e-commerce.scss"


import Header from "../components/e-commerce/Header";


const OrderConfirmation = () => {
    const navigate = useNavigate();


    useEffect(() => {
        navigate('/order-confirmation', { replace: true }); // Replace the current route
        window.history.pushState(null, '', '/'); // Push the home route to the history stack
    
        // Add an event listener for the popstate event
        const handlePopState = () => {
            navigate('/');
        };
    
        window.addEventListener('popstate', handlePopState);
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, [navigate]);

    const placeOrder = useSelector(selectOrder);
    return (
        <Fragment>
            <Header />
            <Row>
                <Col lg="12" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                    <Card>
                        <Card.Body >
                            <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", gap: 10, alignItems: "center", padding: 100 }}>
                                <Col>
                                    <img src={successIcon} />
                                </Col>
                                <Col>
                                    <h4>Order Placed Sucessfully ShopPoint #{generateOrderId(placeOrder?.order_id)}</h4>
                                </Col>
                                <Col>
                                    <p>An email confirmation has been sent to you. If you didn’t receive the email confirmation, email customerservice@sdaap.com.</p>
                                </Col>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default OrderConfirmation;