import React, { useState } from "react";

// plugin
import ReactSlider from "react-slider";

const RangeSlider = (props) => {
  return (
    <>
      <div className="product-range">
        <ReactSlider
          className="horizontal-slider pb-2"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={props?.value}
          ariaLabel={["Lower thumb", "Upper thumb"]}
          onChange={(currentPriceState) => {
            props.onChange([...currentPriceState])
          }}
          max={props?.higherValue} // Set the max value to something greater than 100
          min={props?.lowerValue}
          step={1}
        />
      </div>
      <div className="d-flex justify-content-between mt-5">
        <small id="lower-value">${Math.round(props?.lowerValue ? props?.lowerValue : 1)}</small>
        <small id="upper-value">${Math.round(props?.higherValue ? props?.higherValue : 2000)}</small>
      </div>
    </>
  );
};

export default RangeSlider;
