import React, { useState, memo, useEffect, Fragment } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Row,
  Col,
  Image,
  Nav,
  Tab,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link, useParams } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";
import "../assets/modules/e-commerce/scss/e-commerce.scss"

import Header from "../components/e-commerce/Header";
import Card from "../components/bootstrap/card";
import ShareOffcanvas from "../components/partials/components/shareoffcanvas";

import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from '../redux/product/productSelector';
import { getProductDetailAction } from '../actions/productActions'
import { addToCartAction } from '../actions/cartActions'
import { selectCart } from '../redux/cart/cartSelector';
import { getCarts } from '../actions/cartActions'


import img1 from "../assets/modules/e-commerce/images/product-detail/10.png";
import img2 from "../assets/modules/e-commerce/images/product-detail/11.png";
import img3 from "../assets/modules/e-commerce/images/product-detail/12.png";
import img4 from "../assets/modules/e-commerce/images/product-detail/13.png";
import img5 from "../assets/modules/e-commerce/images/product-detail/14.png";
import img6 from "../assets/modules/e-commerce/images/user/04.png";
import img7 from "../assets/modules/e-commerce/images/user/01.png";

// Sweetalert2
import Swal from "sweetalert2";

import noImageAvailable from "../assets/images/no-image.png";


const handleError = (e) => {
  e.target.src = noImageAvailable;
};


const ProductDetails = () => {
  const [open, setOpen] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const cartItems = useSelector(selectCart);

  const [option1, setOption1] = useState([]);
  const [option2, setOption2] = useState([]);
  const [option3, setOption3] = useState([]);


  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [productPrice, setProductPrice] = useState(0.00);

  useEffect(() => {
    dispatch(getProductDetailAction(product_id))
    dispatch(getCarts())
  }, [product_id])


  useEffect(() => {
    configData()
  }, [])


  const getOption1 = (product_variants) => {
    const sizes = [];
    product_variants?.map((variant) => {
      if (variant?.option1) {
        sizes.push(variant?.option1);
      }
    });
    const unique = sizes.filter(onlyUnique);
    return unique;
  };

  const getProductPrice = () => {
    let price = product?.price;
    product?.variants?.map(v => {
    
      if ((v?.option1 == selectedOption1 || v?.size == selectedOption1) && (v?.option2 == selectedOption2 || v?.color == selectedOption2) && (v?.option3 == selectedOption3 || v?.metarial == selectedOption3)) {
        price = parseFloat(v?.price);
      }
    });
    setProductPrice(parseFloat(price).toFixed(2));
  };


  const getSelectedVariant = () => {
    let selectedVariant = product?.variants[0];
    const f = product?.variants?.find(v =>
      (v?.option1 == selectedOption1 || v?.size == selectedOption1) &&
      (v?.option2 == selectedOption2 || v?.color == selectedOption2) &&
      (v?.option3 == selectedOption3 || v?.material == selectedOption3)
    );
    if (f) {
      selectedVariant = f;
    }
    return selectedVariant;
  };  


  useEffect(() => {
    getProductPrice();
  }, [selectedOption1, selectedOption2, selectedOption3, product]);

  const getOption2 = (product_variants) => {
    const colors = [];
    product_variants?.map((variant) => {
      if (variant?.option2) {
        colors.push(variant?.option2);
      }
    });
    const unique = colors.filter(onlyUnique);
    return unique;
  };

  const getOption3 = (product_variants) => {
    const materials = [];
    product_variants?.map((variant) => {
      if (variant?.option3) {
        materials.push(variant?.option3);
      }
    });
    const unique = materials.filter(onlyUnique);
    return unique;
  };


  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  const configData = () => {
    if (product) {
      const op1 = getOption1(product?.variants);
      const op2 = getOption2(product?.variants);
      const op3 = getOption3(product?.variants);
      setOption1(op1);
      setOption2(op2);
      setOption3(op3);

      const availableVariant = product?.variants?.find(v => v?.inventory_quantity > 0 && v?.is_active == true);
      setSelectedOption1(availableVariant ? availableVariant?.option1 : null);
      setSelectedOption2(availableVariant ? availableVariant?.option2 : null);
      setSelectedOption3(availableVariant ? availableVariant?.option3 : null);
    }
  };



  const checkAlreadyAdded = () => {
    if(getSelectedVariant()?.id)
    {
      let findAlreadyAdded = cartItems?.items?.find((item) => item?.product?.id == product?.id && item.variant.id == getSelectedVariant()?.id)
      if (findAlreadyAdded) {
          return true;
      }
      else {
        return false;
  
      }
    }
    else
    {
      let findAlreadyAdded = cartItems?.items?.find((item) => item?.product?.id == product?.id)
      if (findAlreadyAdded) {
          return true;
      }
      else {
        return false;
  
      }
    }
  
  }

  const addToCartHandler = (item) => {
    dispatch(addToCartAction({
      "product": item?.id,
      "variant": getSelectedVariant()?.id ? getSelectedVariant()?.id : null,
      "quantity": 1
    }))
  }


  const handleOption1Change = (event) => {
    setSelectedOption1(event.target.value);
  };

  const handleOption2Change = (event) => {
    setSelectedOption2(event.target.value);
  };

  const handleOption3Change = (event) => {
    setSelectedOption3(event.target.value);
  };

  return (
    <Fragment>
      <Header />
      <Row>
        <Col lg={12} style={{ padding: 20 }}>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg="7">
                  <div className="product-vertical-slider">
                    <div className="slider__flex">
                      <div className="slider__col">
                        <div className="slider__thumbs">
                          <Swiper
                            className="swiper-container"
                            direction={"vertical"}
                            onSwiper={setThumbsSwiper}
                            slidesPerView={3}
                            spaceBetween={20}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Thumbs]}
                            loop={true}
                            breakpoints={{
                              0: {
                                direction: "horizontal",
                              },
                              768: {
                                direction: "vertical",
                              },
                            }}
                          >

                            {
                              product?.products_images?.map((image => (
                                <SwiperSlide>
                                  <div className="slider__image">

                                    <Image alt={image?.id} src={image?.image} onError={handleError} />
                                  </div>
                                </SwiperSlide>
                              )))
                            }


                          </Swiper>
                        </div>
                      </div>
                      <div className="slider__images">
                        <Swiper
                          className="swiper-container"
                          direction={"vertical"}
                          spaceBetween={10}
                          thumbs={{
                            swiper:
                              thumbsSwiper && !thumbsSwiper.destroyed
                                ? thumbsSwiper
                                : null,
                          }}
                          mousewheel={true}
                          modules={[FreeMode, Thumbs, Mousewheel]}
                          loop={true}
                          breakpoints={{
                            0: {
                              direction: "horizontal",
                            },
                            768: {
                              direction: "vertical",
                            },
                          }}
                        >

                          {
                            product?.products_images?.map((image => (
                              <SwiperSlide>
                                <div className="slider__image">
                                  <Image alt={image?.id} src={image?.image} onError={handleError} />
                                </div>
                              </SwiperSlide>
                            )))
                          }

                        </Swiper>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="5" className="mt-4 mt-lg-0">
                  <div className="border-bottom">
                    <div className="d-flex flex-column align-content-between justify-items-center mb-3">
                      <div className="d-flex justify-content-between mb-2">
                        <h2 className="mb-0">{product?.name}</h2>
                        <div className="d-flex justify-content-end ">
                          <ShareOffcanvas share={true} />
                        </div>
                      </div>
                      <div className=" d-flex align-items-center w-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-24"
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                            fill="#FFD329"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-24"
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                            fill="#FFD329"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-24"
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                            fill="#FFD329"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-24"
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                            fill="#FFD329"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon-24"
                          width="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                            fill="#FFD329"
                          ></path>
                        </svg>
                        <h6 className="ms-2 mb-0">5.0</h6>
                      </div>
                    </div>
                    {(product?.variants[0]?.name !== "Default Title" && product?.variants[0]?.name !== "Title" && option1?.length > 0) && (
                      <>
                        <div className="size-details">
                          <div className="title">
                            {product?.product_options?.find(v => v?.sequence == 0)?.name}:{' '}
                          </div>
                          <ul>
                            {option1?.map((color) => {
                              return (
                                <li key={color}>
                                  <input
                                    type="radio"
                                    name="color"
                                    // disabled={!availableOption1.includes(color)}
                                    value={color}
                                    id={`${color.toLowerCase()}`}
                                    checked={selectedOption1 == color}
                                    onChange={(event) =>
                                      handleOption1Change(event)
                                    }
                                  />
                                  <label htmlFor={`${color.toLowerCase()}`}>
                                    {color}
                                  </label>
                                </li>

                              );
                            })}
                          </ul>
                        </div>
                      </>
                    )}


                    {(product?.variants[0]?.name !== "Default Title" && product?.variants[0]?.name !== "Title" && option2?.length > 0) && (
                      <>
                        <div className="size-details">
                          <div className="title">
                            {product?.product_options?.find(v => v?.sequence == 1)?.name}:{' '}
                          </div>
                          <ul>
                            {option2?.map((color) => {
                              return (
                                <li key={color}>
                                  <input
                                    type="radio"
                                    name="size"
                                    // disabled={!availableOption1.includes(color)}
                                    value={color}
                                    id={`${color.toLowerCase()}`}
                                    checked={selectedOption2 == color}
                                    onChange={(event) =>
                                      handleOption2Change(event)
                                    }
                                  />
                                  <label htmlFor={`${color.toLowerCase()}`}>
                                    {color}
                                  </label>
                                </li>

                              );
                            })}
                          </ul>
                        </div>
                      </>
                    )}


                    {(product?.variants[0]?.name !== "Default Title" && product?.variants[0]?.name !== "Title" && option3?.length > 0) && (
                      <>
                        <div className="size-details">
                          <div className="title">
                            {product?.product_options?.find(v => v?.sequence == 2)?.name}:{' '}
                          </div>
                          <ul>
                            {option3?.map((color) => {
                              return (
                                <li key={color}>
                                  <input
                                    type="radio"
                                    name="material"
                                    // disabled={!availableOption1.includes(color)}
                                    value={color}
                                    id={`${color.toLowerCase()}`}
                                    checked={selectedOption3 == color}
                                    onChange={(event) =>
                                      handleOption3Change(event)
                                    }
                                  />
                                  <label htmlFor={`${color.toLowerCase()}`}>
                                    {color}
                                  </label>
                                </li>

                              );
                            })}
                          </ul>
                        </div>
                      </>
                    )}

                    <div className="d-flex my-4">
                      <h4 className="mb-0"> Price:</h4>
                      <h4 className="text-primary mb-0 ms-2">${productPrice}</h4>
                    </div>
                  </div>
                  <div className="border-bottom mb-3">
                    <p className="py-4 mb-0">
                      {product?.description}
                    </p>
                  </div>
                
                  <div>
                    <div className="d-flex py-4 flex-wrap gap-4">
                      {
                        checkAlreadyAdded() ?
                          <Link
                            to="/carts"
                            className="btn btn-warning d-flex align-items-center gap-2"
                          >
                            <span className="btn-inner d-flex ">
                              <svg
                                width="20"
                                className="icon-20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.4"
                                  d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>
                            Go To Cart
                          </Link> :
                          <button
                            className="btn btn-primary d-flex align-items-center cart-btn  gap-2"
                            onClick={() => addToCartHandler(product)}
                          >
                            <span className="btn-inner d-flex">
                              <svg
                                width="20"
                                className="icon-20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.4"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.91064 20.5886C5.91064 19.7486 6.59064 19.0686 7.43064 19.0686C8.26064 19.0686 8.94064 19.7486 8.94064 20.5886C8.94064 21.4186 8.26064 22.0986 7.43064 22.0986C6.59064 22.0986 5.91064 21.4186 5.91064 20.5886ZM17.1606 20.5886C17.1606 19.7486 17.8406 19.0686 18.6806 19.0686C19.5106 19.0686 20.1906 19.7486 20.1906 20.5886C20.1906 21.4186 19.5106 22.0986 18.6806 22.0986C17.8406 22.0986 17.1606 21.4186 17.1606 20.5886Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M20.1907 6.34909C20.8007 6.34909 21.2007 6.55909 21.6007 7.01909C22.0007 7.47909 22.0707 8.13909 21.9807 8.73809L21.0307 15.2981C20.8507 16.5591 19.7707 17.4881 18.5007 17.4881H7.59074C6.26074 17.4881 5.16074 16.4681 5.05074 15.1491L4.13074 4.24809L2.62074 3.98809C2.22074 3.91809 1.94074 3.52809 2.01074 3.12809C2.08074 2.71809 2.47074 2.44809 2.88074 2.50809L5.26574 2.86809C5.60574 2.92909 5.85574 3.20809 5.88574 3.54809L6.07574 5.78809C6.10574 6.10909 6.36574 6.34909 6.68574 6.34909H20.1907ZM14.1307 11.5481H16.9007C17.3207 11.5481 17.6507 11.2081 17.6507 10.7981C17.6507 10.3781 17.3207 10.0481 16.9007 10.0481H14.1307C13.7107 10.0481 13.3807 10.3781 13.3807 10.7981C13.3807 11.2081 13.7107 11.5481 14.1307 11.5481Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>
                            Add to cart
                          </button>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Tab.Container defaultActiveKey="first">
                <Nav className="tab-bottom-bordered">
                  <div
                    className="mb-0 nav nav-tabs"
                    id="nav-tab1"
                    role="tablist"
                  >
                    <Nav.Link
                      eventKey="first"
                      id="nav-description-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-description"
                      type="button"
                      role="tab"
                      aria-controls="nav-description"
                      aria-selected="true"
                    >
                      Description
                    </Nav.Link>
                    <Nav.Link
                      id="nav-info-tab"
                      eventKey="second"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-info"
                      type="button"
                      role="tab"
                      aria-controls="nav-info"
                      aria-selected="false"
                    >
                      Info
                    </Nav.Link>
                    <Nav.Link
                      id="nav-review-tab"
                      eventKey="thired"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-review"
                      type="button"
                      role="tab"
                      aria-controls="nav-review"
                      aria-selected="false"
                    >
                      Reviews(2)
                    </Nav.Link>
                  </div>
                </Nav>
                <Tab.Content
                  className="mt-4 iq-tab-fade-up"
                  id="nav-tabContent"
                >
                  <Tab.Pane
                    eventKey="first"
                    id="nav-description"
                    role="tabpanel"
                    aria-labelledby="nav-description-tab"
                  >
                    <div className="d-flex flex-column">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lacus eleifend ultrices vestibulum tempor augue bibendum
                        dolor quam malesuad.Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Lacus eleifend ultrices
                        vestibulum tempor augue bibendum dolor quam malesuad.
                      </p>
                      <Row className="row-cols-2 row-cols-lg-5 py-3">
                        <Col>
                          <Image
                            src={img1}
                            alt="product-details"
                            className="img-fluid iq-product-img rounded-2 w-100 "
                            loading="lazy"
                          />
                        </Col>
                        <Col>
                          <Image
                            src={img2}
                            alt="product-details"
                            className="img-fluid iq-product-img rounded-2  w-100 "
                            loading="lazy"
                          />
                        </Col>
                        <Col>
                          <Image
                            src={img3}
                            alt="product-details"
                            className="img-fluid iq-product-img rounded-2 w-100  "
                            loading="lazy"
                          />
                        </Col>
                        <Col>
                          <Image
                            src={img4}
                            alt="product-details"
                            className="img-fluid iq-product-img rounded-2  w-100 "
                            loading="lazy"
                          />
                        </Col>
                        <Col>
                          <Image
                            src={img5}
                            alt="product-details"
                            className="img-fluid iq-product-img rounded-2  w-100  "
                            loading="lazy"
                          />
                        </Col>
                      </Row>
                      <p className="mb-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lacus eleifend ultrices vestibulum tempor augue bibendum
                        dolor quam malesuad.Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Lacus eleifend ultrices
                        vestibulum tempor augue bibendum dolor quam malesuad.
                      </p>
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lacus eleifend ultrices vestibulum tempor augue bibendum
                        dolor quam malesuad. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Lacus eleifend ultrices
                        vestibulum tempor augue bibendum dolor quam malesuad.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    id="nav-info"
                    role="tabpanel"
                    aria-labelledby="nav-info-tab"
                  >
                    <Table bordered className="mb-0" responsive>
                      <tbody>
                        <tr>
                          <th>Type</th>
                          <td>Backpack</td>
                        </tr>
                        <tr>
                          <th>Ideal For</th>
                          <td>Men & Women</td>
                        </tr>
                        <tr>
                          <th>Trolley Support</th>
                          <td>No</td>
                        </tr>
                        <tr>
                          <th>Laptop Sleeve</th>
                          <td>No</td>
                        </tr>
                        <tr>
                          <th>With Rain Cover</th>
                          <td>No</td>
                        </tr>
                        <tr>
                          <th>Style Code</th>
                          <td>Provogue_Day_002</td>
                        </tr>
                        <tr>
                          <th>Color Code</th>
                          <td>Grey, Black</td>
                        </tr>
                        <tr>
                          <th>Material, Waterproof</th>
                          <td>Material: Polyester, Waterproof: Yes</td>
                        </tr>
                        <tr>
                          <th>Number of Compartments</th>
                          <td>2</td>
                        </tr>
                        <tr>
                          <th>Pattern</th>
                          <td>Self Design</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="thired"
                    id="nav-review"
                    role="tabpanel"
                    aria-labelledby="nav-review-tab"
                  >
                    <h4>Review</h4>
                    <div>
                      <Card className="shadow-none bg-transparent border">
                        <Card.Body>
                          <div className="d-flex flex-sm-nowrap flex-wrap">
                            <div>
                              <Image
                                className="img-fluid object-contain avatar-120 rounded"
                                src={img6}
                                alt="01"
                                loading="lazy"
                              />
                            </div>
                            <div className="ms-0 ms-sm-3">
                              <div className="d-flex justify-content-between align-items-center my-2 my-lg-0">
                                <h6 className="mb-0">Emma Jones</h6>
                                <Link className="text-dark " to="#">
                                  Reply
                                </Link>
                              </div>
                              <small className="text-primary">
                                March 01st 2021
                              </small>
                              <p className="mt-2 mb-0">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam ut eu morbi tincidunt
                                erat egestas quisque ultrices ut. Vel elementum
                                blandit et tellus sit tincidunt.
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card className="shadow-none bg-transparent border">
                        <Card.Body>
                          <div className="d-flex flex-sm-nowrap flex-wrap">
                            <div>
                              <Image
                                className="img-fluid object-contain avatar-120 rounded"
                                src={img7}
                                alt="01"
                                loading="lazy"
                              />
                            </div>
                            <div className="ms-0 ms-sm-3">
                              <div className="d-flex justify-content-between align-items-center my-2 my-lg-0">
                                <h6 className="mb-0">Jackson Jones</h6>
                                <Link className="text-dark " to="#">
                                  Reply
                                </Link>
                              </div>
                              <small className="text-primary">
                                March 20th 2021
                              </small>
                              <p className="mt-3">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam ut eu morbi tincidunt
                                erat egestas quisque ultrices ut. Vel elementum
                                blandit et tellus sit tincidunt.
                              </p>
                              <div className="d-flex mb-3">
                                <Link className="" to="#">
                                  Reply To Jackson Jones
                                </Link>
                                <Link className="text-body ms-3" to="#">
                                  Cancel Reply
                                </Link>
                              </div>
                              <Col lg="12">
                                <div className="form-group ">
                                  <Form.Control
                                    type="text"
                                    placeholder=" Hi there, I love your blog "
                                  />
                                </div>
                              </Col>
                              <div className="d-flex">
                                <Button
                                  type="submit"
                                  className="primary rounded"
                                >
                                  Get Started
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <p className="my-4">- There are no reviews yet</p>
                    <h4 className="mb-3">Be the first to Review</h4>
                    <form>
                      <Row>
                        <Col lg="6">
                          <div className="form-group">
                            <Form.Label htmlFor="full-name">
                              First Name<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="w-100"
                              id="full-name"
                              placeholder=" John"
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="form-group">
                            <Form.Label
                              htmlFor="last-name"
                              className="form-label"
                            >
                              Email ID<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="w-100"
                              id="last-name"
                              placeholder="XYZ@exampleemail.com "
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="form-group">
                            <Form.Label htmlFor="message">
                              Enter your Review
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <textarea
                              className="form-control"
                              rows="5"
                              id="message"
                              aria-label="With textarea"
                              spellCheck="false"
                              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                            ></textarea>
                          </div>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-between">
                          <div className="form-check mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck1"
                            >
                              Save my name, email, and website in this browser
                              for the next time I comment.
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </Col>
                        <div className="d-flex">
                          <Button type="submit" className="primary">
                            Submit
                          </Button>
                        </div>
                      </Row>
                    </form>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Fragment>
  );
};

export default ProductDetails;