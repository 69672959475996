import React, { useState, Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Row,
  Col,
  Form,
  Button
} from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import "swiper/css";

import { useDispatch, useSelector } from 'react-redux';

import { selectCart, selectProducts } from '../redux/cart/cartSelector';

import { getCarts, addToCartAction, deleteCartAction } from '../actions/cartActions'

import { selectAddress } from '../redux/address/addressSelector';
import { getAddresses, addAddress } from '../actions/addressActions'


import { selectOrderAddress } from '../redux/order/orderSelector';

import { addOrder } from '../actions/orderAction'

import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "../components/bootstrap/card";

import * as yup from "yup";


import img1 from "../assets/modules/e-commerce/images/product-detail/10.png";
import img2 from "../assets/modules/e-commerce/images/product-detail/11.png";
import img3 from "../assets/modules/e-commerce/images/product-detail/12.png";
import img4 from "../assets/modules/e-commerce/images/product-detail/13.png";


// Sweetalert2
import Swal from "sweetalert2";

import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";

import "../assets/modules/e-commerce/scss/e-commerce.scss"
import Header from "../components/e-commerce/Header";

const schema = yup.object().shape({
  card_holder_name: yup.string().required('Card holder name is required'),
  card_number: yup.string().required('Card number is required'),
  expiry_month: yup.string().required('Expiry Month is required'),
  expiry_year: yup.string().nullable().required("Expiry year is required."),
  cvv: yup.string().required('CVV is required'),
  billing_first_name: yup.string().required('First name is required'),
  billing_last_name: yup.string().required('Last name is required'),
  billing_contact_no: yup.string().required('Contact No. is required'),
  billing_email: yup.string().required('Email is required'),
  billing_address: yup.string().required('Address is required'),
  billing_city: yup.string().required('City is required'),
  billing_state: yup.string().required('State is required'),
  billing_zip: yup.string().required('Zipcode is required'),
});



const Payment = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCart);
  const addresses = useSelector(selectAddress);
  const orderAddress = useSelector(selectOrderAddress);


  let navigate = useNavigate();


  useEffect(() => {
    dispatch(getCarts())
    dispatch(getAddresses())

  }, [])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // name: "",
      // description: "",
      // category: "",
      // inventory_quantity: "",
      // sku: "",
      // barcode: "",
      // weight: "",
      // status: "",


    },
  });


  const placeOrder = async (data) => {

  
    let bodyData = {
      "billing_address": {
        "first_name": data?.billing_first_name,
        "last_name": data?.billing_last_name,
        "contact_no": data?.billing_contact_no,
        "email": data?.billing_email,
        "address1": data?.billing_address,
        "address2": null,
        "city": data?.billing_city,
        "state": data?.billing_state,
        "zipcode": data?.billing_zip,
        "is_default": false,

      },
      "shipping_address": {
        "first_name": orderAddress?.first_name,
        "last_name": orderAddress?.last_name,
        "contact_no": orderAddress?.contact_no,
        "email": orderAddress?.email,
        "address1": orderAddress?.address1,
        "address2": null,
        "city": orderAddress?.city,
        "state": orderAddress?.state,
        "zipcode": orderAddress?.zipcode,
        "is_default": orderAddress?.is_default,

      },
      "card_details": {
        "card_number": data?.card_number,
        "cvv":data?.cvv,
        "expiry_month": data?.expiry_month,
        "expiry_year": data?.expiry_year,
        "card_holder_name": data?.card_holder_name
      }
    }
    let isPlaced = await dispatch(addOrder(bodyData))

    if (isPlaced) {
      navigate("/order-confirmation");
    }
  }


  const fetchImage = (images) => {

    if (images && images.length > 0) {
      let findMainImage = images.find((item) => item.is_main === true)

      if (findMainImage) {
        return findMainImage?.image
      }
      else {
        return images[0]?.image
      }
    }

  }

  const calculateSubTotal = () => {
    let subtotal = 0;
    if (cartItems && cartItems?.items?.length > 0) {

      cartItems?.items?.map(item => {

        if (item?.variant) {
          subtotal += (item.variant.price * item.quantity);
        }
        else if (!item?.variant && item?.product) {
          subtotal += (item.product.price * item.quantity);
        }
      });

      return subtotal.toFixed(2);
    }
    else {
      return 0.00
    }

  }

  const onAddressSubmit = async (data) => {
    console.log('data', data);

    let isAdded = await dispatch(addAddress(data))

    if (isAdded) {
      reset()
    }
    console.log('isAdded', isAdded);

  }


  const orderTotal = () => {
    let subtotal = 0;
    if (cartItems && cartItems?.items?.length > 0) {

      cartItems?.items?.map(item => {

        if (item?.variant) {
          subtotal += (item.variant.price * item.quantity);
        }
        else if (!item?.variant && item?.product) {
          subtotal += (item.product.price * item.quantity);
        }
      });

      return subtotal.toFixed(2);
    }
    else {
      return 0.00
    }

  }


  const handleQuantity = (item, quantity) => {
    if (quantity >= 1) {
      dispatch(addToCartAction({
        "product": item?.product?.id,
        "variant": null,
        "quantity": quantity
      }, false))
      console.log("quantity", quantity);
    }


  }

  const [productList, setProductList] = useState([
    {
      image: img1,
      name: "Biker’s Jacket",
      color: "Red & Black",
      size: " L",
      price: "$80.00",
    },
    {
      image: img2,
      name: "Pink Sweater",
      color: "Pink",
      size: "M",
      price: "$70.00",
    },
    {
      image: img3,
      name: "Beats Headphones",
      color: "Green",
      size: "M",
    },
    {
      image: img4,
      name: "Shoes",
      color: "Yellow",
      size: "8",
    },
  ]);
  function Sweetalert() {
    Swal.fire({
      icon: "success",
      title: "Added!",
      text: "Your item has been Added to the cart.",
    });
  }
  const cart = () => {
    document.getElementById("cart").classList.add("show");
    document.getElementById("checkout").classList.remove("show");
    document.getElementById("payment").classList.remove("show");
    document.getElementById("iq-tracker-position-1").classList.add("active");
    document.getElementById("iq-tracker-position-2").classList.remove("active");
    // document.getElementById("iq-tracker-position-2").classList.add("done");
    // document.getElementById("iq-tracker-position-3").classList.add("active");
  };

  const checkout = () => {
    document.getElementById("cart").classList.remove("show");
    document.getElementById("checkout").classList.add("show");
    document.getElementById("iq-tracker-position-1").classList.remove("active");
    document.getElementById("iq-tracker-position-1").classList.add("done");
    document.getElementById("iq-tracker-position-2").classList.add("active");
  };
  const payment = () => {
    document.getElementById("checkout").classList.remove("show");
    document.getElementById("payment").classList.add("show");
    document.getElementById("iq-tracker-position-2").classList.remove("active");
    document.getElementById("iq-tracker-position-2").classList.add("done");
    document.getElementById("iq-tracker-position-3").classList.add("active");
  };

  const handleRemove = (index, id) => {
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCartAction(id))
      }
    });
  };

  return (
    <Fragment>
      <Header />
      <Row>
        <ul
          className="text-center iq-product-tracker mb-0 py-4"
          id="progressbar"
        >
          <li
            className=" iq-tracker-position-0 done"
            id="iq-tracker-position-1"
          >
            Cart
          </li>
          <li className="iq-tracker-position-0 done" id="iq-tracker-position-2" >
            Checkout
          </li>
          <li className="iq-tracker-position-0 active " id="iq-tracker-position-3">
            Payment
          </li>
        </ul>

        <div id="payment" className="iq-product-tracker-card b-0 show">
          <Row>
            <Col lg="2">
            </Col>
            <Col lg="5">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Payment</h4>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col lg="12">
                        <div className="form-group">
                          <Form.Label htmlFor="card_holder_name">Name on card</Form.Label>
                          <Form.Control type="text" id="card_holder_name" name="card_holder_name"
                            {...register('card_holder_name', {
                              required: true,
                            })} />

                          {errors.card_holder_name && <span className="error-msg" style={{ color: 'red' }}>{errors.card_holder_name.message}</span>}

                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="form-group">
                          <Form.Label htmlFor="card_number">Card number</Form.Label>
                          <Form.Control type="text" id="card_number"   {...register('card_number', {
                            required: true,
                            maxLength:16
                          })}
                          maxLength={16}
                          />

                          {errors.card_number && <span className="error-msg" style={{ color: 'red' }}>{errors.card_number.message}</span>}
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="form-group">
                          <Form.Label htmlFor="expiry_month">Expiry Month</Form.Label>
                          <Form.Control type="email" id="expiry_month"  {...register('expiry_month', {
                            required: true,
                          })} 
                          maxLength={2}
                          />

                          {errors.expiry_month && <span className="error-msg" style={{ color: 'red' }}>{errors.expiry_month.message}</span>}
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="form-group">
                          <Form.Label htmlFor="expiry_year">Expiry Year</Form.Label>
                          <Form.Control type="email" id="expiry_year"  {...register('expiry_year', {
                            required: true,
                          })} 
                          maxLength={2}
                          />

                          {errors.expiry_year && <span className="error-msg" style={{ color: 'red' }}>{errors.expiry_year.message}</span>}
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="form-group">
                          <Form.Label htmlFor="cvv">CVV</Form.Label>
                          <Form.Control type="email" id="cvv"  {...register('cvv', {
                            required: true,
                          })} 
                          maxLength={4}
                          />

                          {errors.cvv && <span className="error-msg" style={{ color: 'red' }}>{errors.cvv.message}</span>}
                        </div>
                      </Col>
                      <Col lg="12">
                        <h4 className="mb-5">Billing Address</h4>
                      </Col>
                   
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_first_name">First Name</Form.Label>
                          <Form.Control type="text" id="billing_first_name" name="billing_first_name"

                            {...register('billing_first_name', {
                              required: true,
                            })} />

                          {errors.billing_first_name && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_first_name.message}</span>}

                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_last_name">Last Name</Form.Label>
                          <Form.Control type="text" id="billing_last_name"   {...register('billing_last_name', {
                            required: true,
                          })} />

                          {errors.billing_last_name && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_last_name.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_contact_no">Contact Number</Form.Label>
                          <Form.Control type="text" id="billing_contact_no"  {...register('billing_contact_no', {
                            required: true,
                          })} />

                          {errors.billing_contact_no && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_contact_no.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_email">Email ID</Form.Label>
                          <Form.Control type="email" id="billing_email"  {...register('billing_email', {
                            required: true,
                          })} />

                          {errors.billing_email && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_email.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_address">
                            Address
                          </Form.Label>
                          <Form.Control type="text" id="billing_address" {...register('billing_address', {
                            required: true,
                          })} />

                          {errors.billing_address && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_address.message}</span>}
                        </div>
                      </Col>
                       
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_city">Town/ City</Form.Label>
                          <Form.Control type="text" id="billing_city"  {...register('billing_city', {
                            required: true,
                          })} />

                          {errors.billing_city && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_city.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_state">
                            State/ Country
                          </Form.Label>
                          <Form.Control type="text" id="billing_state"  {...register('billing_state', {
                            required: true,
                          })} />

                          {errors.billing_state && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_state.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="billing_zip">
                            Postcode/ ZIP Code
                          </Form.Label>
                          <Form.Control type="text" id="billing_zip" {...register('billing_zip', {
                            required: true,
                          })} />

                          {errors.billing_zip && <span className="error-msg" style={{ color: 'red' }}>{errors.billing_zip.message}</span>}
                        </div>
                      </Col>
 

                      <div className="d-flex">
                        <Button type="button" onClick={handleSubmit(placeOrder)} className="btn btn-primary">
                          Pay Now
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Billing Details</h4>
                </Card.Header>
                <Card.Body>
                  <hr />
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Subtotal</h6>
                      <h6 className="mb-0 text-primary">${calculateSubTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Shipping</h6>
                      <h6 className="mb-0 text-primary">FREE</h6>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <h6 className="mb-0">Amount Payable</h6>
                    <h6 className="mb-0 text-primary">${orderTotal()}</h6>
                  </div>
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
              </Card>
            </Col>
            <Col lg="2">
            </Col>
          </Row>
        </div>
      </Row>
    </Fragment>
  );
};

export default Payment;