import React, { useState, memo, Fragment, useEffect, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Collapse } from "react-bootstrap";

import { getCarts } from '../actions/cartActions'

import { useDispatch, useSelector } from 'react-redux';

import { selectCategorys, selectProducts } from '../redux/product/productSelector';

import { getProductListAction, getCategoryListAction } from '../actions/productActions'

import ProductCard from "../components/ProductCard";
import ProductCardListView from "../components/ProductCardListView";

import FilterOptions from "../components/FilterOptions";
import RangeSlider from "../components/RangeSlider";
import Card from "../components/bootstrap/card";

import Header from "../components/e-commerce/Header";
import debounce from 'lodash/debounce';

import img1 from "../assets/modules/e-commerce/images/offers/01.png";
import img2 from "../assets/modules/e-commerce/images/product/04.png";
import img3 from "../assets/modules/e-commerce/images/product/05.png";
import img4 from "../assets/modules/e-commerce/images/product/06.png";
import img5 from "../assets/modules/e-commerce/images/product/07.png";
import img6 from "../assets/modules/e-commerce/images/product/10.png";

import noProduct from "../assets/modules/e-commerce/images/no-product.png";

import noImageAvailable from "../assets/images/no-image.png";

import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";

import "../assets/modules/e-commerce/scss/e-commerce.scss"

const ProductList = () => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const categorys = useSelector(selectCategorys);


  const [filter, setFilter] = useState({
    "search": null,
    "min_price": 1,
    "categories": [],
    "max_price": 200000,
    "paging": {
      "limit": 1000,
      "offset": 0
    }
  });


  const searchFilterHandler = useCallback(
    debounce((e) => {
      console.log(0)
      setFilter({ ...filter, search: e.target.value })
    }, 300),
    []
  );


  const priceFilterHandler = useCallback(
    debounce((newValue) => {
      setFilter({ ...filter, min_price: newValue[0], max_price: newValue[1] })
    }, 300),
    []
  );


  const categoriesFilterHandler = (e) => {
    if (e.target.checked) {
      setFilter({ ...filter, categories: [...filter?.categories, e.target.value] })
    }
    else {
      let tmpCategory = filter?.categories.filter((item) => item != e.target.value)
      setFilter({ ...filter, categories: tmpCategory })
    }
  }


  useEffect(() => {
    dispatch(getCategoryListAction())
    dispatch(getProductListAction(filter))
    dispatch(getCarts())
  }, [])

  useEffect(() => {
    dispatch(getProductListAction(filter))
  }, [filter])

  const fetchImage = (images) => {
    if (images && images.length > 0) {
      let findMainImage = images.find((item) => item.is_main === true)
      if (findMainImage) {
        return findMainImage?.image
      }
      else {
        return images[0]?.image
      }
    }
  }

  return (
    <Fragment>
      <Header />
      <Row style={{ padding: 10 }}>
        <Col lg="9">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h4 className="mb-0">Products</h4>
            <ul
              className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item dropdown d-none d-xl-block">
                <div className="form-group input-group mb-0 search-input w-100 ">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    onChange={searchFilterHandler}

                  />
                  <span className="input-group-text">
                    <svg
                      width="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-grid-view"
              role="tabpanel"
              aria-labelledby="grid-view-tab"
            >
              <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                {
                  products && products?.rows?.length > 0 ? products?.rows?.map((item) => (
                    <Col>
                      <ProductCard
                        productId={item.id}
                        productName={item.name}
                        productImage={fetchImage(item.products_images)}
                        productRating="3.5"
                        productPrice={'$' + item?.price}
                      />
                    </Col>
                  )) : <Col style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <img src={noProduct} />
                  </Col>
                }

              </Row>
            </div>
            <div
              className="tab-pane fade"
              id="pills-list-view"
              role="tabpanel"
              aria-labelledby="list-view-tab"
            >
              <Row className="row-cols-1">
                <Col>
                  <ProductCardListView
                    productName="Casual Shoes"
                    productImage={img1}
                    productRating="3.9"
                    productPrice="$56.00"
                    statusColor="success"
                    statusIdentity="In Stock"
                    deliveryDate="Thu Jan 30"
                  />
                  <ProductCardListView
                    productName="Biker's Jacket"
                    productImage={img2}
                    productRating="3.5"
                    productPrice="$65.00"
                    statusColor="danger"
                    statusIdentity="Out of Stock"
                    deliveryDate="Thu Jan 31"
                  />
                  <ProductCardListView
                    productName="Knitted Shrug"
                    productImage={img3}
                    productRating="3.5"
                    productPrice="$65.00"
                    statusColor="warning"
                    statusIdentity="Limited"
                    deliveryDate="Thu Jan 22"
                  />
                  <ProductCardListView
                    productName="Blue Handbag"
                    productImage={img4}
                    productRating="3.5"
                    productPrice="$65.00"
                    statusColor="success"
                    statusIdentity="In Stock"
                    deliveryDate="Thu Jan 25"
                  />
                  <ProductCardListView
                    productName="Pink Triusers"
                    productImage={img5}
                    productRating="3.5"
                    productPrice="$65.00"
                    statusColor="success"
                    statusIdentity="In Stock"
                    deliveryDate="Thu Jan 26"
                  />
                  <ProductCardListView
                    productName="Pink Handbag"
                    productImage={img6}
                    productRating="3.5"
                    productPrice="$65.00"
                    statusColor="success"
                    statusIdentity="In Stock"
                    deliveryDate="Thu Jan 25"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg="3">
          <Card className="iq-filter-card">
            <Card.Header className="border-bottom px-0 py-4 mx-4">
              <h4 className="list-main mb-0">Filters</h4>
            </Card.Header>
            <Card.Body>
              <Collapse in={open}>
                <div id="iq-product-filter-01">
                  <div className="mt-2">
                    <h5 className="py-3 mb-0">Price</h5>
                    <RangeSlider lowerValue={1} higherValue={200000} onChange={priceFilterHandler} value={[filter?.min_price, filter?.max_price,]} />

                    <div className="product-ratings mt-2">
                      <h5 className="py-3 mb-0">Categories</h5>
                      <div className="collapse show" id="iq-product-filter-02">
                        {
                          categorys && categorys?.length ? (
                            categorys.map((category) => (
                              <FilterOptions
                                uniqueName="type"
                                id={category?.id}
                                productName={category?.name}
                                value={category?.id}
                                onChange={categoriesFilterHandler}
                                productChecked={filter?.categories.includes(category?.id) ? "Checked" : false}
                              />
                            ))
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProductList;