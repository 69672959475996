import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  isLoginSuccess: false,
  isLoginError: false,
  loginError: null,
  isRegisterSuccess: false,
  isVerifyEmailSent: false,
  user: null,
  access_token: '',
  refresh_token: '',
  redirectAfterLogin: "",
  statusIndicator: {
    billing: false,
    products: false,
    store: false,
    onboarding: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      state.access_token = '';
      state.refresh_token = '';
    },
    setLoggedIn: (state, action) => {
      state.isLoginSuccess = true;
      state.isLoggedIn = true;
    }
  },
});

/**
 * Actions
 */
export const {
  logOut,
  setLoggedIn,
} = authSlice.actions;

/**
 * Reducers
 */
export const authReducer = authSlice.reducer;
