import {
  setLoggedIn
} from '../redux/auth/authSlice';

import fetchUrl from "../api";
import { USER_APIS, GUEST_LOGIN,KEYS } from "../utils/constant";
import { toast } from "react-hot-toast";

  
export function guestLogin(data) {
  return async (dispatch) => {
    try {
      const type = 'post';
      const url = `${USER_APIS?.login}`;

      const response = await fetchUrl({ type, url, data: GUEST_LOGIN });
      if (response) {
        localStorage.setItem(KEYS.authToken, response.access_token);
        localStorage.setItem(KEYS.domain, response.domain);
        localStorage.setItem(KEYS.authType, response.auth_type);
        localStorage.setItem(KEYS.sub, response.sub);
        dispatch(setLoggedIn());
        return true
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
            ? err.response.data.errors
            : 'Something went worng', {
        autoClose: false,
      }
      );
    }
  };
}