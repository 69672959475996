const { KEYS } = require("./constant");
const { LocalStorage } = require("./localStorage");

const setDeviceId = () => {
    // eslint-disable-next-line no-magic-numbers
    const uuid = Math.floor(Math.random() * 1000000000000);
    const date = new Date();
    const timestamp = date.getTime();

    LocalStorage.set(KEYS.deviceId, `${uuid}-${timestamp}`);
};

export const  generateOrderId = (value) => {
    if (value < 10000) {
      return value.toString().padStart(4, '0');
    }
    return value.toString();
}

const logout = () => {};

export { setDeviceId, logout };
