import {
  setAddress
} from '../redux/address/addressSlice';

import fetchUrl from "../api";
import { USER_APIS, ECOMMERCE_APIS } from "../utils/constant";
import { toast } from "react-hot-toast";

import Swal from "sweetalert2";

function Sweetalert() {
  Swal.fire({
    icon: "success",
    title: "Added!",
    text: "Your item has been Added to the cart.",
  });
}
 

export function getAddresses() {
  return async (dispatch) => {
    try {
      const type = 'get';
      const url = `${ECOMMERCE_APIS.GET_ADDRESS}`;

      const response = await fetchUrl({ type, url });
      if (response && response.data && response.status == 200) {
        dispatch(setAddress(response.data));
      } else {
        dispatch(setAddress([]));
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      dispatch(setAddress([]));
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
            ? err.response.data.errors
            : 'Something went worng', {
        autoClose: false,
      }
      );
    }
  };
}


export function addAddress(data) {
  return async (dispatch) => {
    try {

      const type = 'post';
      const url = `${ECOMMERCE_APIS.ADD_ADDRESS}`;

      const response = await fetchUrl({ type, url, data: data });
      if (response && response.data && response.status == 201) {
        dispatch(getAddresses());
        return true
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
            ? err.response.data.errors
            : 'Something went worng', {
        autoClose: false,
      }
      );
    }
  };
}


export function deleteAddress(id) {
  return async (dispatch) => {
    try {
      const type = 'delete';
      const url = `${ECOMMERCE_APIS.DELETE_ADDRESS.replace('<address_id>', id)}`;
      const response = await fetchUrl({ type, url });
      if (response && response.status == 200) {
        dispatch(getAddresses());
        return true
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
        return false

      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
            ? err.response.data.errors
            : 'Something went worng', {
        autoClose: false,
      }
      );
    }
  };
}