import {
  setCart
} from '../redux/cart/cartSlice';

import fetchUrl from "../api";
import { USER_APIS, ECOMMERCE_APIS } from "../utils/constant";
import { toast } from "react-hot-toast";

import Swal from "sweetalert2";

function Sweetalert() {
  Swal.fire({
    icon: "success",
    title: "Added!",
    text: "Your item has been Added to the cart.",
  });
}

function CartItemDeltedAlert(){
  Swal.fire("Deleted!", "Your cart item has been deleted.", "success");
}  


export function getCarts() {
  return async (dispatch) => {
    try {
      const type = 'get';
      const url = `${ECOMMERCE_APIS.GET_CART}`;

      const response = await fetchUrl({ type, url });
      if (response && response.data && response.status == 200) {
        dispatch(setCart(response.data));
      } else {
        dispatch(setCart([]));
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      dispatch(setCart([]));
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}


export function addToCartAction(data,showAlert = true) {
  return async (dispatch) => {
    try {

      const type = 'post';
      const url = `${ECOMMERCE_APIS.ADD_CART}`;

      const response = await fetchUrl({ type, url,data:data });
      if (response && response.data && response.status == 200) {
        dispatch(getCarts());
        if(showAlert)
        {
          Sweetalert()
        }
      } else {
        console.log('response',response);
        Swal.fire({
          icon: "error",
          title: response?.message
        })
       }
    } catch (err) {

      console.log('err---->',err);
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}


export function deleteCartAction(id,showAlert = true) {
  return async (dispatch) => {
    try {

      const type = 'delete';
      const url = `${ECOMMERCE_APIS.DELETE_CART_ITEM.replace('<cart_item_id>',id)}`;
      const response = await fetchUrl({ type, url });
      if (response && response.status == 200) {
        dispatch(getCarts());

        if(showAlert)
        {
          CartItemDeltedAlert()
        }
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}