import {
  setCart,
  
} from '../redux/cart/cartSlice';

import {
  setPlacedOrder,
} from '../redux/order/orderSlice';

import fetchUrl from "../api";
import { USER_APIS, ECOMMERCE_APIS } from "../utils/constant";
import { toast } from "react-hot-toast";

import Swal from "sweetalert2";
import { getCarts } from './cartActions';

function Sweetalert() {
  Swal.fire({
    icon: "success",
    title: "Added!",
    text: "Your item has been Added to the cart.",
  });
}

function CartItemDeltedAlert(){
  Swal.fire("Deleted!", "Your cart item has been deleted.", "success");
}  


export function getOrders() {
  return async (dispatch) => {
    try {
      const type = 'get';
      const url = `${ECOMMERCE_APIS.GET_CART}`;

      const response = await fetchUrl({ type, url });
      if (response && response.data && response.status == 200) {
        dispatch(setCart(response.data));
      } else {
        dispatch(setCart([]));
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      dispatch(setCart([]));
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}


export function addOrder(data,showAlert = true) {
  return async (dispatch) => {
    try {

      const type = 'post';
      const url = `${ECOMMERCE_APIS.ADD_ORDER}`;

      const response = await fetchUrl({ type, url,data:data });
      if (response && response.data && response.status == 201) {
        dispatch(setPlacedOrder(response.data));
        dispatch(getCarts());


        // if(showAlert)
        // {
        //   Sweetalert()
        // }
        return true
      } else {
        toast.error('Something went worng', {
          autoClose: false,
        });
      }
    } catch (err) {
      toast.error(
        err &&
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length &&
          err.response.data.errors[0] &&
          err.response.data.errors[0].password
          ? err.response.data.errors[0].password
          : err && err.response && err.response.data && err.response.data.errors
          ? err.response.data.errors
          : 'Something went worng', {
            autoClose: false,
          }
      );
    }
  };
}


 