import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  address: null,
  order: null,

};

const orderSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setOrderAddress: (state,action) => {
      state.address = action.payload;
    },
    setPlacedOrder: (state,action) => {
      state.order = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
  setOrderAddress,
  setPlacedOrder
} = orderSlice.actions;

/**
 * Reducers
 */
export const orderReducer = orderSlice.reducer;
