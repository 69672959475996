import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  counter: 0,
  products:{
    count:0,
    rows:0,
  },
  categorys:[],

  product:null

};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCounter: (state,action) => {
      state.counter = action.payload;
    },

    setProducts: (state,action) => {
      state.products = action.payload;
    },
    setProduct: (state,action) => {
      state.product = action.payload;
    },
    setCategorys: (state,action) => {
      state.categorys = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
  setCounter,
  setProducts,
  setProduct,
  setCategorys
} = productSlice.actions;

/**
 * Reducers
 */
export const productReducer = productSlice.reducer;
