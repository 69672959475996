import React, { useState, Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Row,
  Col,
  Image,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from 'react-router-dom'

import { useNavigate } from "react-router-dom";

import "swiper/css";

import { useDispatch, useSelector } from 'react-redux';

import { selectCart } from '../redux/cart/cartSelector';

import { getCarts, addToCartAction } from '../actions/cartActions'

import { selectAddress } from '../redux/address/addressSelector';
import { getAddresses, addAddress, deleteAddress } from '../actions/addressActions'


import { selectOrderAddress } from '../redux/order/orderSelector';
import { setOrderAddress } from '../redux/order/orderSlice'

import { addOrder } from '../actions/orderAction'




import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "../components/bootstrap/card";

import * as yup from "yup";


import img1 from "../assets/modules/e-commerce/images/product-detail/10.png";
import img2 from "../assets/modules/e-commerce/images/product-detail/11.png";
import img3 from "../assets/modules/e-commerce/images/product-detail/12.png";
import img4 from "../assets/modules/e-commerce/images/product-detail/13.png";
import img5 from "../assets/modules/e-commerce/images/product-detail/14.png";


// Sweetalert2
import Swal from "sweetalert2";

import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";

import "../assets/modules/e-commerce/scss/e-commerce.scss"
import Header from "../components/e-commerce/Header";

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  contact_no: yup.string().required('Contact No. is required'),
  email: yup.string().nullable().required("Email is required."),
  address1: yup.string().required('Address is required'),
  // address2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipcode: yup.string().required('Zipcode is required'),
});



const Checkout = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCart);
  const addresses = useSelector(selectAddress);
  const orderAddress = useSelector(selectOrderAddress);


  let navigate = useNavigate();



  const setDefaultAddress = () => {
    addresses.map((address) => {
      if (address.is_default == true) {
        dispatch(setOrderAddress(address))
      }
    })
  }


  useEffect(() => {
    setDefaultAddress()
  }, [addresses])



  useEffect(() => {
    dispatch(getCarts())
    dispatch(getAddresses())

  }, [])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });


  const placeOrder = async () => {
    let bodyData = {
      "billing_address": {
        "first_name": orderAddress?.first_name,
        "last_name": orderAddress?.last_name,
        "contact_no": orderAddress?.contact_no,
        "email": orderAddress?.email,
        "address1": orderAddress?.address1,
        "address2": null,
        "city": orderAddress?.city,
        "state": orderAddress?.state,
        "zipcode": orderAddress?.zipcode,
        "is_default": orderAddress?.is_default,

      },
      "shipping_address": {
        "first_name": orderAddress?.first_name,
        "last_name": orderAddress?.last_name,
        "contact_no": orderAddress?.contact_no,
        "email": orderAddress?.email,
        "address1": orderAddress?.address1,
        "address2": null,
        "city": orderAddress?.city,
        "state": orderAddress?.state,
        "zipcode": orderAddress?.zipcode,
        "is_default": orderAddress?.is_default,

      },
      "card_details": {
        "card_number": "4111111111111111",
        "cvv": "123",
        "expiry_month": "4",
        "expiry_year": "2030",
        "card_holder_name": "Deepak Suthar"
      }
    }
    let isPlaced = await dispatch(addOrder(bodyData))

    if (isPlaced) {
      navigate("/invoice");
    }
  }

  const calculateSubTotal = () => {
    let subtotal = 0;
    if (cartItems && cartItems?.items?.length > 0) {

      cartItems?.items?.map(item => {

        if (item?.variant) {
          subtotal += (item.variant.price * item.quantity);
        }
        else if (!item?.variant && item?.product) {
          subtotal += (item.product.price * item.quantity);
        }
      });

      return subtotal.toFixed(2);
    }
    else {
      return 0.00
    }

  }

  const onAddressSubmit = async (data) => {
    console.log('data', data);

    let isAdded = await dispatch(addAddress(data))

    if (isAdded) {
      reset()
      Swal.fire({
        icon: "success",
        title: "Added!",
        text: "Your address has been Added.",
      });
    }

  }


  const orderTotal = () => {
    let subtotal = 0;
    if (cartItems && cartItems?.items?.length > 0) {

      cartItems?.items?.map(item => {

        if (item?.variant) {
          subtotal += (item.variant.price * item.quantity);
        }
        else if (!item?.variant && item?.product) {
          subtotal += (item.product.price * item.quantity);
        }
      });

      return subtotal.toFixed(2);
    }
    else {
      return 0.00
    }

  }


  const handleQuantity = (item, quantity) => {
    if (quantity >= 1) {
      dispatch(addToCartAction({
        "product": item?.product?.id,
        "variant": null,
        "quantity": quantity
      }, false))
      console.log("quantity", quantity);
    }


  }

  const [productList, setProductList] = useState([
    {
      image: img1,
      name: "Biker’s Jacket",
      color: "Red & Black",
      size: " L",
      price: "$80.00",
    },
    {
      image: img2,
      name: "Pink Sweater",
      color: "Pink",
      size: "M",
      price: "$70.00",
    },
    {
      image: img3,
      name: "Beats Headphones",
      color: "Green",
      size: "M",
    },
    {
      image: img4,
      name: "Shoes",
      color: "Yellow",
      size: "8",
    },
  ]);
  function Sweetalert() {
    Swal.fire({
      icon: "success",
      title: "Added!",
      text: "Your item has been Added to the cart.",
    });
  }
  const cart = () => {
    navigate(-1);
    // document.getElementById("cart").classList.add("show");
    // document.getElementById("checkout").classList.remove("show");
    // document.getElementById("payment").classList.remove("show");
    // document.getElementById("iq-tracker-position-1").classList.add("active");
    // document.getElementById("iq-tracker-position-2").classList.remove("active");
    // document.getElementById("iq-tracker-position-2").classList.add("done");
    // document.getElementById("iq-tracker-position-3").classList.add("active");
  };

  const checkout = () => {
    document.getElementById("cart").classList.remove("show");
    document.getElementById("checkout").classList.add("show");
    document.getElementById("iq-tracker-position-1").classList.remove("active");
    document.getElementById("iq-tracker-position-1").classList.add("done");
    document.getElementById("iq-tracker-position-2").classList.add("active");
  };
  const payment = () => {
    navigate("/payment");
  };

  const handleRemove = async (id) => {
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this address",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await dispatch(deleteAddress(id))

        if (response) {
          Swal.fire("Deleted!", "Your address has been deleted.", "success");
        }

      }
    });
  };

  return (
    <Fragment>
      <Header />
      <Row>
        <ul
          className="text-center iq-product-tracker mb-0 py-4"
          id="progressbar"
        >
          <li
            className="iq-tracker-position-0 done"
            id="iq-tracker-position-1"
          >
            Cart
          </li>
          <li className="iq-tracker-position-0 active" id="iq-tracker-position-2">
            Checkout
          </li>
          <li className="iq-tracker-position-0 " id="iq-tracker-position-3">
            Payment
          </li>
        </ul>
        <div id="checkout" className="iq-product-tracker-card b-0 show">
          <Row>
            <Col lg="8">

              {
                addresses && addresses.length > 0 ?
                  <Card>
                    <Card.Body>
                      <h4 className="mb-0">Most Recently Used</h4>
                      <Row>
                        {
                          addresses.slice(0, 3).map((address, index) => (
                            <Col lg="4">
                              <div className="mt-4">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="address"
                                    id={'address_' + index}

                                    onClick={() => dispatch(setOrderAddress(address))}
                                    checked={orderAddress?.id === address?.id}
                                  // defaultChecked
                                  />
                                  <label
                                    className="form-check-label mb-0"
                                    htmlFor={'address_' + index}
                                  >
                                    <span className="h6 mb-0">Billing Address</span>
                                  </label>
                                </div>
                                <p className="mb-0">{address?.first_name} {address?.last_name}</p>
                                <p className="mb-0">
                                  {address?.address1}, {address?.city}, {address?.state}. {address?.zipcode}{" "}
                                </p>
                                <div className="mt-2 d-flex gap-1">

                                  <Link to="javscript:void(0);" onClick={() => handleRemove(address.id)}>Remove </Link>
                                </div>
                              </div>
                            </Col>
                          ))
                        }
                      </Row>
                    </Card.Body>
                  </Card> : null
              }

              <Card>
                <Card.Header>
                  <h4 className="mb-0">Add New Address</h4>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="fName">First Name</Form.Label>
                          <Form.Control type="text" id="fName" name="first_name"

                            {...register('first_name', {
                              required: true,
                            })} />

                          {errors.first_name && <span className="error-msg" style={{ color: 'red' }}>{errors.first_name.message}</span>}

                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="LName">Last Name</Form.Label>
                          <Form.Control type="text" id="LName"   {...register('last_name', {
                            required: true,
                          })} />

                          {errors.last_name && <span className="error-msg" style={{ color: 'red' }}>{errors.last_name.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Cno">Contact Number</Form.Label>
                          <Form.Control type="email" id="Cno"  {...register('contact_no', {
                            required: true,
                          })} />

                          {errors.contact_no && <span className="error-msg" style={{ color: 'red' }}>{errors.contact_no.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="email">Email ID</Form.Label>
                          <Form.Control type="text" id="email"  {...register('email', {
                            required: true,
                          })} />

                          {errors.email && <span className="error-msg" style={{ color: 'red' }}>{errors.email.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="HName">
                            Address
                          </Form.Label>
                          <Form.Control type="text" id="HName" {...register('address1', {
                            required: true,
                          })} />

                          {errors.address1 && <span className="error-msg" style={{ color: 'red' }}>{errors.address1.message}</span>}
                        </div>
                      </Col>

                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Town">Town/ City</Form.Label>
                          <Form.Control type="text" id="Town"  {...register('city', {
                            required: true,
                          })} />

                          {errors.city && <span className="error-msg" style={{ color: 'red' }}>{errors.city.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="State">
                            State/ Country
                          </Form.Label>
                          <Form.Control type="text" id="State"  {...register('state', {
                            required: true,
                          })} />

                          {errors.state && <span className="error-msg" style={{ color: 'red' }}>{errors.state.message}</span>}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group">
                          <Form.Label htmlFor="Zip">
                            Postcode/ ZIP Code
                          </Form.Label>
                          <Form.Control type="text" id="Zip" {...register('zipcode', {
                            required: true,
                          })} />

                          {errors.zipcode && <span className="error-msg" style={{ color: 'red' }}>{errors.zipcode.message}</span>}
                        </div>
                      </Col>

                      <Col lg="12" className="d-flex my-4">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="Daddress"
                            className="form-check-input"
                            name="is_default"
                            {...register('is_default', {

                            })} />

                          {errors.is_default && <span className="error-msg" style={{ color: 'red' }}>{errors.is_default.message}</span>}
                          <label
                            className="form-check-label"
                            htmlFor="Daddress"
                          >
                            Make this my default address.
                          </label>
                        </div>
                      </Col>
                      <div className="d-flex">
                        <Button type="button" onClick={handleSubmit(onAddressSubmit)} className="btn btn-primary">
                          Submit
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Payment Summary</h4>
                </Card.Header>
                <Card.Body>
                   
                  <div className="border-bottom mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Subtotal</h6>
                      <h6 className="mb-0 text-primary">${calculateSubTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Shipping</h6>
                      <h6 className="mb-0 text-primary">FREE</h6>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Order Total</h6>
                      <h6 className="mb-0 text-primary">${orderTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <Button
                        id="backbutton"
                        href="#"
                        variant=""
                        onClick={cart}
                        className="btn-primary-subtle d-block back justify-content-between"
                      >
                        Back
                      </Button>
                      <Button
                        id="deliver-address"
                        href="#"
                        onClick={payment}
                        className="btn btn-primary d-block"
                      >
                        Place Order
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <div id="payment" className="iq-product-tracker-card b-0">
          <Row>
            <Col lg="8">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Payment Options</h4>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="d-flex mt-3">
                      <Image className="img-fluid" src={img5} alt="01" />
                      <h6 className="mb-0 ms-3">
                        Barclays Bank Debit Card ending in 7852
                      </h6>
                    </div>
                    <h6 className="mb-0 mt-3">Elon Musk</h6>
                    <h6 className="mb-0 mt-3">06 / 2030</h6>
                  </div>
                  <Form className="mt-4">
                    <div className="d-flex align-items-center">
                      <div className="mb-4">
                        <Form.Label>
                          <span className="h6">Enter CVV</span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="xxx" required />
                      </div>
                      <Button
                        type="submit"
                        className="btn btn-primary mt-2 ms-3"
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                  <hr />
                  <div className="card-lists">
                    <div className="form-group mt-4">
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="credit"
                          name="customRadio"
                          className="form-check-input"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="credit"
                        >
                          <span className="h6">Credit / Debit / ATM Card</span>
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="netbaking"
                          name="customRadio"
                          className="form-check-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="netbaking"
                        >
                          <span className="h6"> Net Banking</span>
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="emi"
                          name="customRadio"
                          className="form-check-input"
                        />
                        <label className="custom-control-label" htmlFor="emi">
                          <span className="h6">EMI (Easy Installment)</span>
                        </label>
                      </div>
                      <div className="form-check mb-4">
                        <input
                          type="radio"
                          id="cod"
                          name="customRadio"
                          className="form-check-input"
                        />
                        <label className="custom-control-label" htmlFor="cod">
                          <span className="h6">Cash On Delivery</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" className="btn btn-primary mt-2">
                      Add Gift Card
                    </Button>
                    <Button
                      className="btn btn-primary mt-2"
                      href="#"
                      role="button"
                    >
                      cancel
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Header>
                  <h4 className="mb-0">Billing Details</h4>
                </Card.Header>
                <Card.Body>
                  <hr />
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Subtotal</h6>
                      <h6 className="mb-0 text-primary">${calculateSubTotal()}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mb-0">Shipping</h6>
                      <h6 className="mb-0 text-primary">FREE</h6>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <h6 className="mb-0">Amount Payable</h6>
                    <h6 className="mb-0 text-primary">${orderTotal()}</h6>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <Button
                    type="button"
                    className="btn btn-primary mt-2 ms-3"

                    onClick={() => placeOrder()}
                  >
                    Finish Payment
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      </Row>
    </Fragment>
  );
};

export default Checkout;