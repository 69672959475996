import React, { useState, memo, Fragment } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {   Row,
  Col,
  Image,
  Nav,
  Tab,
  Form,
  Button,
  Table, } from "react-bootstrap";
import { BrowserRouter, Link } from 'react-router-dom'

import moment from "moment";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Autoplay } from "swiper";
// import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";

import "swiper/css";
import { useDispatch, useSelector } from 'react-redux';
import { selectOrder } from '../redux/order/orderSelector';


import ProductCard from "../components/ProductCard";
import ProductCardListView from "../components/ProductCardListView";
import OfferCard from "../components/OfferCard";

import FilterOptions from "../components/FilterOptions";
import FilterRating from "../components/FilterRating";
import RangeSlider from "../components/RangeSlider";
import Card from "../components/bootstrap/card";

import ShareOffcanvas from "../components/partials/components/shareoffcanvas";


import img1 from "../assets/modules/e-commerce/images/product-detail/10.png";
import img2 from "../assets/modules/e-commerce/images/product-detail/11.png";
import img3 from "../assets/modules/e-commerce/images/product-detail/12.png";
import img4 from "../assets/modules/e-commerce/images/product-detail/13.png";
import img5 from "../assets/modules/e-commerce/images/product-detail/14.png";
import img6 from "../assets/modules/e-commerce/images/user/04.png";
import img7 from "../assets/modules/e-commerce/images/user/01.png";
import img8 from "../assets/modules/e-commerce/images/01.png";
import img9 from "../assets/modules/e-commerce/images/02.png";
import img10 from "../assets/modules/e-commerce/images/03.png";
import img11 from "../assets/modules/e-commerce/images/04.png";
import img12 from "../assets/modules/e-commerce/images/05.png";
import img13 from "../assets/modules/e-commerce/images/06.png";
import img14 from "../assets/modules/e-commerce/images/product-detail/2.png";
import img15 from "../assets/modules/e-commerce/images/product-detail/3.png";
import img16 from "../assets/modules/e-commerce/images/product-detail/4.png";
import img17 from "../assets/modules/e-commerce/images/product-detail/5.png";
import img18 from "../assets/modules/e-commerce/images/product-detail/1.png";
import Counter from "../components/counter";


// Sweetalert2
import Swal from "sweetalert2";
// import "shepherd.js/dist/css/shepherd.css";
// import "flatpickr/dist/flatpickr.css";
// import "choices.js/public/assets/styles/choices.min.css";
import "../assets/scss/hope-ui.scss";
import "../assets/scss/pro.scss";
import "../assets/scss/custom.scss";
import "../assets/scss/rtl.scss";
import "../assets/scss/customizer.scss";
import "../assets/custom/scss/custom.scss";

import "../assets/modules/e-commerce/scss/e-commerce.scss"

const translationPercentage = 10;
const scaleValues = { x: 0.9, y: 1 };

const ProductDetail = () => {
    const [open, setOpen] = useState(true);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const placeOrder = useSelector(selectOrder);
    console.log('placeOrder',placeOrder);

    const Table = [
      {
          name: 'Item 1',
          discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          price: '$2,100.00',
          quantity: '1',
          total: '$2,100.00'
      },
      {
          name: 'Item 2',
          discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          price: '$4,205.00',
          quantity: '2',
          total: '$8,410.00'
      },
      {
          name: 'Item 3',
          discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          price: '$1,500',
          quantity: '3',
          total: '$4,500.00'
      }
  ]

    return (
      <Fragment>
      <Row>
          <Col lg="12">
              <Card>
                  <Card.Body>
                      <Row>
                          <div className="col-12">
                              <div className="d-flex justify-content-between mb-3">
                                  <h4 className="mb-0">Order No.  #{placeOrder?.id}</h4>
                                  <span>SDaaP</span>
                              </div>
                              <h5 className="mb-4">Elon Musk</h5>
                              <Row>
                                  <Col lg="4" sm="12" className="mt-3 mt-sm-0">
                                      <div>
                                          <h6>Bill To:</h6>
                                          <p>
                                              Elon Musk
                                          </p>
                                          <p className="mb-0">
                                              4517 Washington Ave. Manchester, Kentucky 39495
                                          </p>
                                      </div>
                                  </Col>
                                  <Col lg="3" sm="12" className="mt-3 mt-sm-0">
                                      <div>
                                          <h6>Bill From:</h6>
                                          <p>
                                              SDaaP.
                                          </p>
                                          <p className="mb-0">
                                              2972 Westheimer Rd. Santa Ana, Illinois 85486
                                          </p>
                                      </div>
                                  </Col>
                                  <Col lg="2" sm="12" className="mt-3 mt-sm-0 text-sm-end text-start">
                                      <div>
                                          <p className="text-muted mb-0">
                                              Amount Paid
                                          </p>
                                          <h5 className="mb-0">
                                              ${placeOrder?.total_amount}
                                          </h5>
                                      </div>
                                  </Col>
                                  <Col lg="2" sm="12" className="col-md-6 mt-3 mt-lg-0 text-lg-end text-start">
                                      <div className="mb-3">
                                          <h6 className="mb-0">Order Date</h6>
                                          <p>

                                            { moment(placeOrder?.order_date, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                          </p>
                                      </div>
                                  </Col>
                              </Row>
                          </div>
                          <div className="col-12 mt-4">
                              <div className="table-responsive-lg">
                                  <table className="table iq-billing-table">
                                      <thead>
                                          <tr>
                                              <th scope="col">Description</th>
                                              <th className="text-center" scope="col">Price</th>
                                              <th className="text-center" scope="col">Quantity</th>
                                              <th scope="col">Sub-Total</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              placeOrder?.order_items?.map((item, index) => {
                                                  return (
                                                      <tr key={index}>
                                                          <td className="w-50">
                                                              <h6 className="mb-0">{item.product_name}</h6>
                                                              {/* <p className="mb-0">{item.discription}
                                                              </p> */}
                                                          </td>
                                                          <td className="text-center">${item.unit_price}</td>
                                                          <td className="text-center">{item.quantity}</td>
                                                          <td>${parseFloat(item.unit_price)* parseFloat(item.quantity)}</td>
                                                      </tr>
                                                  )
                                              })
                                          }
                                          <tr>
                                              <td></td>
                                              <td></td>
                                              <td>
                                                  <div className="text-center">
                                                      <h6>Total: </h6>
                                                      <h6>Tax: </h6>
                                                      <h6 className="mb-0">Discount:</h6>
                                                  </div>
                                              </td>
                                              <td>
                                                  <div className="d-flex flex-column">
                                                      <h6>${placeOrder?.total_amount}</h6>
                                                      <h6>$0.00</h6>
                                                      <h6 className="mb-0">${placeOrder?.total_amount}</h6>
                                                  </div>
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colSpan="3">Net Amount</td>
                                              <td>
                                                  <h6 className="mb-0">${placeOrder?.total_amount}</h6>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                          {/* <div className="col-12">
                              <p className="ms-4">
                                  Please pay before the due date. Thank you for your business.
                              </p>
                              <div className="d-flex justify-content-center mt-4">
                                  <Button className="btn btn-primary" string="{window.print()}">Print</Button>
                              </div>
                          </div> */}
                      </Row>
                  </Card.Body>
              </Card>
          </Col>
      </Row>
  </Fragment>
    );
};

export default ProductDetail;