import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  address: [],
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddress: (state,action) => {
      state.address = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
    setAddress,
} = addressSlice.actions;

/**
 * Reducers
 */
export const addressReducer = addressSlice.reducer;
