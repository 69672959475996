import React, { memo } from 'react'
import { Row, Col, Button, Image, Collapse } from "react-bootstrap";
import cart from "../../assets/images/icons/cart.svg";
import emptyCart from "../../assets/images/icons/emptyCart.svg";
import { selectCart } from '../../redux/cart/cartSelector';
import {  useSelector } from 'react-redux';
import {  Link } from 'react-router-dom'



const Header = memo((props) => {
 
    const cartItems = useSelector(selectCart);

 
    return (
    //     <header className="header">
    //     <div className="container">
    //       <div className="logo">
    //         <Link to="/">E-Commerce</Link>
    //       </div>
    //       <nav className="nav">
    //         <ul>
    //           <li><Link to="/">Home</Link></li>
    //           <li><Link to="/products">Products</Link></li>
    //           <li><Link to="/about">About Us</Link></li>
    //           <li><Link to="/contact">Contact</Link></li>
    //           <li><Link to="/cart">Cart</Link></li>
    //         </ul>
    //       </nav>
    //     </div>
    //   </header>
        <Row style={{ background: "#1b92ea",paddingRight:10,paddingTop:30,paddingLeft:10,paddingBottom:30, }}>
            <Col lg="10">
                <Link to="/" className="text-decoration-none">
                <h2 style={{color:"#fff"}}>Ecommerce</h2>
                </Link>
            </Col>
            <Col lg="2">
                <Link to="/carts" className="text-decoration-none">
                {
                    cartItems && cartItems?.items?.length > 0 ?
                    <img src={cart} style={{float:"right"}}/>
                    :
                    <img src={emptyCart} style={{float:"right"}}/>
                }
                </Link>
            </Col>
        </Row>
    )
})

export default Header