import React from 'react';
import ReactDOM from 'react-dom';
import reactToWebComponent from 'react-to-webcomponent';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { BrowserRouter as Router } from "react-router-dom";

import { store, persistor } from './redux/store';

import './App.css';

const WrappedApp = () => (
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>
);


const WebComponent = reactToWebComponent(WrappedApp, React, ReactDOM);
customElements.define('sdapp-webcomponent', WebComponent);
