const KEYS = {
    user: "USER",
    authToken: "AUTH_TOKEN",
    tempData: "TEMP_DATA",
    companyData: "COMPANY_DATA",
    deviceId: "device-id",
    gdprKey: "gdprAccepted",
    email: "EMAIL",
    requestId: "REQUEST_ID",
    rolePermission: "PERMISSION_ROLE",
    adminPermission: "ADMIN_PERMISSION",
    notificationCount: "NOTIFICATION_COUNT",
    domain: "X-DOMAIN",
    sub: "X-SUB",
    authType: "X-LOGIN-TYPE",
};

const USER_APIS = {
    login: "user/login/",
}

const REGISTRATION_SCHEMA = ["email", "password", "confirm_password"];
const PASSWORD_CHANGE_SCHEMA = ["current_password", "new_password", "confirm_new_password"];


const ECOMMERCE_APIS = {
    CREATE_CATEGORY: "ecommerce/categories/",
    GET_CATEGORY: "ecommerce/categories/",
    GET_CATEGORY_DETAIL: "ecommerce/categories/<category_id>",
    UPDATE_CATEGORY_DETAIL: "ecommerce/categories/<category_id>",

    CREATE_PRODUCT: "ecommerce/products/",

    GET_ACTIVE_CATEGORY: "ecommerce/active-categories/",
    GET_PRODUCTS: "ecommerce/get-all-products/",
    GET_PRODUCT_DETAIL: "ecommerce/products/<product_id>",



    PRODUCT_IMAGE_UPLOAD: "ecommerce/images/",
    ADD_CART: "ecommerce/carts/",
    GET_CART: "ecommerce/carts/",
    DELETE_CART_ITEM: "ecommerce/delete-cart-item/<cart_item_id>",

    ADD_ADDRESS: "ecommerce/address/",
    DELETE_ADDRESS: "ecommerce/address/<address_id>",
    GET_ADDRESS: "ecommerce/address/",

    ADD_ORDER: "ecommerce/orders/",


}


const GUEST_LOGIN = { "email": "yzIwI+Xj0timtDqkE8ysxhL7XitbNUPmLN7eG4KJTDo=", "password": "QsDIF6x/qJy8MTNcR/b0OA==", "remember_me": false, "invitation_id": null }



module.exports = {
    KEYS,
    USER_APIS,
    REGISTRATION_SCHEMA,
    PASSWORD_CHANGE_SCHEMA,
    ECOMMERCE_APIS,
    GUEST_LOGIN
};
