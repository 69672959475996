import React , { useEffect } from 'react';

import { useRoutes } from "react-router-dom";

import { guestLogin } from "./actions/authActions";

import { loginSuccess } from "./redux/auth/authSelector";

import { useSelector, useDispatch } from 'react-redux';

import ProductList from './pages/ProductsList'
import ProductsDetails from './pages/ProductsDetails'
import OrderProcess from './pages/OrderProcess'
import Checkout from './pages/Checkout'
import Payment from './pages/Payment'
import OrderConfirmation from './pages/OrderConfirmation'
import Invoice from './pages/Invoice'




const App = () => {

  const loggedInUser = useSelector(loginSuccess);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(guestLogin())
  }, [loggedInUser])

  console.log('loggedInUser',loggedInUser);


  const routes = useRoutes([
    { path: '/', element: <ProductList /> },
    { path: '/product-details/:product_id', element: <ProductsDetails /> },
    { path: '/carts', element: <OrderProcess /> },
    { path: '/checkout', element: <Checkout /> },
    { path: '/payment', element: <Payment /> },
    { path: '/order-confirmation', element: <OrderConfirmation /> },
    { path: '/invoice', element: <Invoice /> },
  ]);
  return <>
    {
      loggedInUser ? routes : null
    }
  </>
}

export default App;
